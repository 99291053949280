<template>
  <div class="content">
    <PageHeader :title="$t('rcs-component.title')" />
    <div class="page-content container-fluid">
      <div class="card card-tabs">
        <div class="card-header">
          <ul class="nav nav-tabs primary-tabs">
            <li class="nav-item nav-m-custom" role="presentation">
              <a
                href="javascript:void(0)"
                class="nav-link active"
                v-tab="'simple'"
                data-toggle="tab"
                aria-expanded="true"
                @click="changeType('simple')"
                >{{$t('sms.send-msg.tabs.simple.title')}}</a
              >
            </li>
            <li class="nav-item nav-m-custom" role="presentation">
              <a
                href="javascript:void(0)"
                class="nav-link"
                v-tab="'contact'"
                aria-expanded="true"
                @click="changeType('contacts')"
                >{{$tc('sms.send-msg.tabs.contact.title', 2)}}</a
              >
            </li>
            <li class="nav-item nav-m-custom" role="presentation">
              <a
                href="javascript:void(0)"
                class="nav-link"
                v-tab="'group'"
                aria-expanded="true"
                @click="changeType('groups')"
                >{{$t('sms.send-msg.tabs.groups.title')}}</a
              >
            </li>
            <li class="nav-item nav-m-custom" role="presentation">
              <a
                href="javascript:void(0)"
                class="nav-link"
                v-tab="'files'"
                aria-expanded="true"
                @click="changeType('files')"
                >{{$t('sms.send-msg.tabs.archives.title')}}</a
              >
            </li>
          </ul>
        </div>
        <div class="card-body">
          <form action="#" class="form-horizontal">
            <div class="form-body">
              <div class="row">
                <div class="col-lg-8 col-md-8 col-sm-12">
                  <div v-if="shortCodes.length > 1" class="form-group row">
                    <label class="control-label text-left col-3"
                      >{{$t('generic-str.lbl-sender')}}</label
                    >
                    <div class="col-md-6">
                      <select v-model="form.from" class="form-control">
                        <option
                          v-for="shortCode in shortCodes"
                          :key="shortCode.id"
                          :value="shortCode.short_code"
                        >
                          {{ shortCode.short_code }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="control-label text-left col-3">
                      {{$t('usage.lbl-product')}}
                    </label>
                    <div class="col-md-6">
                      <base-select
                        v-model="form.productId"
                        :options="products"
                        :label="'name'"
                      />
                    </div>
                  </div>
                  <!-- <div v-if="form.type != 'simple'" class="form-group row"> -->
                  <div class="form-group row" v-if="form.type != 'simple'">
                    <label class="control-label text-left col-12"
                      >{{$t('sms.send-msg.lbl-campaign')}}</label
                    >
                    <div class="col-md-12">
                      <input v-model="form.name" class="form-control" />
                    </div>
                  </div>
                  <div class="tab-content">
                    <div class="tab-pane active" id="simple">
                      <div class="form-group row">
                        <label class="control-label text-left col-12"
                          >{{$t('sms.send-msg.tabs.simple.lbl-recipient')}}</label
                        >
                        <div class="col-md-12">
                          <textarea
                            v-model="form.to"
                            rows="6"
                            class="form-control"
                            v-bind:class="{
                              invalid:
                                submited &&
                                form.to.length == 0 &&
                                form.type == 'simple',
                            }"
                            :placeholder="this.$t('sms.send-msg.tabs.simple.lbl-example')"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane" id="contact">
                      <div class="form-group row">
                        <label class="control-label text-left col-4"
                          >{{$tc('sms.send-msg.tabs.contact.title', 2)}}</label
                        >
                        <div class="col-md-12 block-el">
                          <div>
                            <button
                              class="btn btn-success"
                              type="button"
                              v-modal="{ target: 'contact-send-modal' }"
                            >
                              {{$t('sms.send-msg.tabs.contact.lbl-add-contact')}}
                            </button>
                            <hr />
                            <b-collapse id="collapse-1" visible class="mt-2">
                              <b-card class="send-group-card">
                                <div
                                  class="text-center table-placeholder"
                                  v-if="contacts.length == 0"
                                >
                                  <i class="fas fa-user font-size-80"></i>
                                  <h5 class="card-title m-t-20">
                                    {{$t('sms.send-msg.tabs.contact.lbl-none-contact')}}
                                  </h5>
                                </div>
                                <p class="card-text" v-if="contacts.length > 0">
                                  Contatos adicionados ({{ contacts.length }})
                                </p>
                                <ul
                                  class="ul-list-groups"
                                  v-if="contacts.length > 0"
                                >
                                  <li
                                    v-for="(contact, i) in contacts"
                                    :key="contact.id"
                                  >
                                    <div
                                      class="
                                        alert
                                        alert-secondary
                                        alert-outline
                                        alert-dismissible
                                        fade
                                        show
                                      "
                                      role="alert"
                                    >
                                      {{ contact.first_name }} /
                                      {{ contact.mobile_number }}
                                      <button
                                        type="button"
                                        class="close"
                                        @click="removeContact(i)"
                                      >
                                        <span
                                          aria-hidden="true"
                                          class="fas fa-times"
                                        ></span>
                                      </button>
                                    </div>
                                  </li>
                                </ul>
                              </b-card>
                            </b-collapse>
                          </div>
                          <!-- <group-table isSendPage="true" />-->
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane" id="group">
                      <div class="form-group row">
                        <label class="control-label text-left col-12"
                          >{{$t('sms.send-msg.tabs.groups.title')}}</label
                        >
                        <div class="col-md-12 block-el">
                          <div>
                            <button
                              class="btn btn-success"
                              type="button"
                              v-modal="{ target: 'group-send-modal' }"
                            >
                              {{$t('sms.send-msg.tabs.groups.lbl-add-group')}}
                            </button>
                            <hr />
                            <b-collapse id="collapse-1" visible class="mt-2">
                              <b-card class="send-group-card">
                                <div
                                  class="text-center table-placeholder"
                                  v-if="groups.length == 0"
                                >
                                  <i class="fas fa-users font-size-80"></i>
                                  <h5 class="card-title m-t-20">
                                    {{$t('sms.send-msg.tabs.groups.lbl-none-group')}}
                                  </h5>
                                </div>
                                <p class="card-text" v-if="groups.length > 0">
                                  {{$t('sms.send-msg.tabs.groups.lbl-added-groups')}} ({{ groups.length }})
                                </p>
                                <ul
                                  class="ul-list-groups"
                                  v-if="groups.length > 0"
                                >
                                  <li
                                    v-for="(group, i) in groups"
                                    :key="group.id"
                                  >
                                    <div
                                      class="
                                        alert
                                        alert-secondary
                                        alert-outline
                                        alert-dismissible
                                        fade
                                        show
                                      "
                                      role="alert"
                                    >
                                      {{ group.name }}
                                      <button
                                        type="button"
                                        class="close"
                                        @click="removeGroup(i)"
                                      >
                                        <span
                                          aria-hidden="true"
                                          class="fas fa-times"
                                        ></span>
                                      </button>
                                    </div>
                                  </li>
                                </ul>
                              </b-card>
                            </b-collapse>
                          </div>
                          <!-- <group-table isSendPage="true" />-->
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane" id="files">
                      <div class="form-group row">
                        <label class="control-label text-left col-3"
                          >{{$t('sms.send-msg.tabs.archives.lbl-add-archive')}}</label
                        >
                        <div v-if="!importing" class="col-md-6">
                          <input
                            ref="uploadedFile"
                            type="file"
                            accept=".csv"
                            hidden
                            @change="uploadFile"
                          />
                          <div class="input-group mb-3">
                            <div class="form-control">{{ form.file }}</div>
                            <div class="input-group-append">
                              <button
                                class="btn btn-primary"
                                type="button"
                                @click="openFileUpload"
                              >
                                <template v-if="form.file"
                                  >{{$t('sms.send-msg.tabs.archives.lbl-change-archive')}}</template
                                >
                                <template v-else>{{$t('sms.send-msg.tabs.archives.lbl-select-archive')}}</template>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div v-else class="col-md-6">
                          <div class="row">
                            <div class="col-12">
                              <small>{{$t('sms.send-msg.tabs.archives.lbl-validating')}}</small>
                            </div>
                          </div>
                          <div class="progress">
                            <div
                              class="
                                progress-bar
                                progress-bar-striped
                                progress-bar-animated
                              "
                              role="progressbar"
                              :style="`width: ${uploadProgress}%;`"
                              aria-valuenow="25"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              {{ uploadProgress }}%
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <a
                            href="/exemplos/example.csv"
                            class="btn btn-bulk btn-success"
                            v-tooltip.top="$t('sms.send-msg.tabs.archives.lbl-tooltip-archive')"
                            >{{$t('generic-str.example')}}</a
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <label class="control-label text-left col-12"
                      >{{$t('options-div.msg-type')}}</label
                    >
                  </div>
                  <div class="form-group row card-send-content">
                    <div class="col-sm-12">
                      <ul
                        class="nav nav-pills nav-pills-info mb-3"
                        id="pills-demo-3"
                        role="tablist"
                      >
                        <li class="nav-item">
                          <a
                            class="nav-link btn-floating c-pointer"
                            :class="{ active: tabs == 'text' }"
                            @click="changeTextTab('text')"
                            ><i class="far fa-file-alt"></i>{{$tc('options-div.types.text', 1)}}</a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link btn-floating c-pointer"
                            :class="{ active: tabs == 'midia' }"
                            @click="changeTextTab('midia')"
                            ><i class="fas fa-photo-video"></i>{{$t('options-div.types.media')}}</a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link btn-floating c-pointer"
                            :class="{ active: tabs == 'card' }"
                            @click="changeTextTab('card')"
                            ><i class="far fa-square"></i>{{$t('options-div.types.card')}}</a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link btn-floating c-pointer"
                            :class="{ active: tabs == 'card-carousel' }"
                            @click="changeTextTab('card-carousel')"
                            ><i class="fas fa-digital-tachograph"></i
                            >{{$t('options-div.types.carousel')}}</a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link btn-floating c-pointer"
                            :class="{ active: tabs == 'link' }"
                            @click="changeTextTab('link')"
                            ><i class="fas fa-link"></i>{{$t('options-div.types.links')}}</a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link btn-floating c-pointer"
                            :class="{ active: tabs == 'calendar' }"
                            @click="changeTextTab('calendar')"
                            ><i class="fas fa-calendar"></i>{{$t('options-div.types.events')}}</a
                          >
                        </li>
                      </ul>
                      <hr />
                    </div>
                    <div class="col-sm-12" v-if="tabs == 'text'">
                      <div class="">
                        <h3>{{$tc('options-div.types.text', 1)}}</h3>
                      </div>
                      <div class="text-right" style="margin-bottom: 5px">
                        <Emojis @select="addEmoji" title="Emoji" />
                        <div
                          class="popup-box-container dropdown to-right"
                          style="margin-left: 5px"
                          v-if="vars.length"
                        >
                          <a href="javascript:void(0)" v-dropdown>
                            <span class="badge badge-primary">{{$t('sms.send-msg.sms-input-component.lbl-variables')}}</span>
                          </a>
                          <div
                            class="dropdown-menu dropdown-menu-right"
                            x-placement="bottom-end"
                          >
                            <a
                              class="dropdown-item"
                              href="javascript:void(0)"
                              v-for="variable in vars"
                              :key="variable.value"
                              @click="addVariable(variable)"
                              >{{ variable.name }}</a
                            >
                          </div>
                        </div>
                      </div>
                      <textarea
                        class="form-control"
                        rows="6"
                        id="sms-body"
                        v-model="rcs.text.content"
                      ></textarea>
                      <div class="textarea-information bg-light text-right">
                        <small class="form-text text-muted">
                          {{ rcs.text.content.length }} {{$t('sms.send-msg.sms-input-component.lbl-chars')}}.
                        </small>
                      </div>
                    </div>

                    <div class="col-sm-12" v-if="tabs == 'link'">
                      <div class="">
                        <h3>{{$t('options-div.types.links')}}</h3>
                      </div>
                      <div class="">
                        <label for="sms-body">{{$tc('options-div.types.text', 1)}}</label>
                      </div>
                      <div class="text-right" style="margin-bottom: 5px">
                        <div
                          class="popup-box-container dropdown to-right"
                          style="margin-left: 5px"
                          v-if="vars.length"
                        >
                          <a href="javascript:void(0)" v-dropdown>
                            <span class="badge badge-primary">{{$t('sms.send-msg.sms-input-component.lbl-variables')}}</span>
                          </a>
                          <div
                            class="dropdown-menu dropdown-menu-right"
                            x-placement="bottom-end"
                          >
                            <a
                              class="dropdown-item"
                              href="javascript:void(0)"
                              v-for="variable in vars"
                              :key="variable.value"
                              @click="addVariableLink(variable)"
                              >{{ variable.name }}</a
                            >
                          </div>
                        </div>
                        <Emojis @select="addEmoji" title="Emoji" />
                      </div>
                      <textarea
                        class="form-control"
                        rows="6"
                        id="sms-body"
                        v-model="rcs.links.messageText"
                      ></textarea>
                      <div class="textarea-information bg-light text-right">
                        <small class="form-text text-muted">
                          {{ rcs.links.messageText.length }} {{$t('sms.send-msg.sms-input-component.lbl-chars')}}.
                        </small>
                      </div>
                      <hr />
                      <div
                        style="margin: 10px; float: left"
                        v-for="(btn, i) in rcs.links.suggestions"
                        :key="i"
                      >
                        <button
                          class="btn btn-primary left-animate"
                          type="button"
                        >
                          {{ btn.action.text }}
                        </button>
                        <i
                          class="fas fa-edit margin-5 c-pointer font-13"
                          @click="linkBtn = btn"
                        ></i>
                        <i
                          @click="btnRemoveLink(i)"
                          class="fas fa-times margin-5 c-pointer font-13"
                        ></i>
                      </div>
                      <div style="clear: both"></div>
                      <hr />
                      <div v-if="linkBtn">
                        <div class="">
                          <h3>{{$t('send-component.btn-edit')}}</h3>
                        </div>
                        <div class="">
                          <label for="sms-body">{{$t('send-component.title')}}</label>
                        </div>
                        <input
                          class="form-control"
                          v-model="linkBtn.action.text"
                        />
                        <div class="">
                          <label for="sms-body">{{$tc('generic-str.status.lbl-response', 1)}}</label>
                        </div>
                        <input
                          class="form-control"
                          v-model="linkBtn.action.postbackData"
                        />
                        <div class="">
                          <label for="sms-body">URL</label>
                        </div>
                        <input
                          class="form-control"
                          v-model="linkBtn.action.openUrlAction.url"
                        />
                        <br />
                        <div>
                          <button
                            class="btn btn-danger btn-sm"
                            @click="linkBtn = null"
                          >
                            <i class="fas fa-times color-white"></i>
                          </button>
                        </div>
                        <hr />
                      </div>
                      <button
                        class="btn btn-info to-right"
                        type="button"
                        @click="btnAddLink"
                      >
                        {{$t('send-component.new-btn')}}
                      </button>
                      <button
                        style="margin-right: 5px"
                        class="btn btn-success to-right"
                        type="button"
                        @click="saveMessage"
                      >
                        {{$t('generic-str.save')}}
                      </button>
                    </div>
                    <div class="col-sm-12" v-if="tabs == 'midia'">
                      <div>
                        <h3>{{$t('options-div.media-link')}}</h3>
                      </div>
                      <div>
                        <div class="alert alert-warning">
                          {{$t('options-div.media-warning')}}
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-md-12">
                          <input
                            type="text"
                            class="form-control"
                            v-model="rcs.midia.fileUrl"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12" v-if="tabs == 'card'">
                      <div class="">
                        <h3>{{$t('options-div.types.card')}}</h3>
                      </div>
                      <div
                        class="shadow-card rounded card-rich"
                        style="width: 500px"
                      >
                        <div class="card-rich-cover relative">
                          <img
                            :src="rcs.richCard.imageUrl"
                            style="width: 500px"
                            id="card-rich-cover-tab"
                          />
                          <ImageUploader
                            @upload="
                              (file) =>
                                (rcs.richCard.imageUrl = file.url)
                            "
                          >
                            <i
                              class="fas fa-edit card-edit-btn absolute"
                            ></i>
                          </ImageUploader>
                        </div>
                        <br />
                        <br />
                        <h2>
                          <div class="relative">
                            <div
                              class="
                                popup-box-container
                                dropdown
                                to-right
                                var-insede-input
                              "
                              style="margin-left: 5px"
                              v-if="vars.length && rcs.richCard.titleEdit"
                            >
                              <a href="javascript:void(0)" v-dropdown>
                                <i
                                  class="fas fa-archive"
                                  v-tooltip.top="`Variáveis`"
                                ></i>
                              </a>
                              <div
                                class="dropdown-menu dropdown-menu-right"
                                x-placement="bottom-end"
                              >
                                <a
                                  class="dropdown-item"
                                  href="javascript:void(0)"
                                  v-for="variable in vars"
                                  :key="variable.value"
                                  @click="addVarCardTitle(variable, i)"
                                  >{{ variable.name }}</a
                                >
                              </div>
                            </div>
                            <span v-if="!rcs.richCard.titleEdit">{{
                              rcs.richCard.messageText
                            }}</span>
                            <input
                              class="form-control"
                              style="width: 100%; float: left"
                              v-model="rcs.richCard.messageText"
                              v-if="rcs.richCard.titleEdit"
                            />
                            <i
                              style="width: 3%; display: block; float: right"
                              class="fas fa-edit c-pointer"
                              @click="rcs.richCard.titleEdit = true"
                              v-if="!rcs.richCard.titleEdit"
                            ></i>
                          </div>
                        </h2>
                        <div style="clear: both"></div>
                        <div class="relative" style="padding: 10px">
                          <span v-if="!rcs.richCard.messageEdit">
                            {{ rcs.richCard.messageDescription }}
                          </span>
                          <textarea
                            class="form-control"
                            style="width: 100%; float: left"
                            v-model="rcs.richCard.messageDescription"
                            v-if="rcs.richCard.messageEdit"
                          ></textarea>
                          <i
                            class="fas fa-edit c-pointer"
                            @click="rcs.richCard.messageEdit = true"
                            v-if="!rcs.richCard.messageEdit"
                          ></i>
                          <div
                            class="
                              popup-box-container
                              dropdown
                              to-right
                              var-insede-input
                            "
                            style="margin-right: 15px; margin-top: 15px"
                            v-if="vars.length && rcs.richCard.messageEdit"
                          >
                            <a href="javascript:void(0)" v-dropdown>
                              <i
                                class="fas fa-archive"
                                v-tooltip.top="`Variáveis`"
                              ></i>
                            </a>
                            <div
                              class="dropdown-menu dropdown-menu-right"
                              x-placement="bottom-end"
                            >
                              <a
                                class="dropdown-item"
                                href="javascript:void(0)"
                                v-for="variable in vars"
                                :key="variable.value"
                                @click="addVarCardDescription(variable, i)"
                                >{{ variable.name }}</a
                              >
                            </div>
                          </div>
                        </div>
                        <hr class="no-margin" />
                        <div
                          style="
                            margin: 10px;
                            margin-bottom: 20px;
                            padding-bottom: 10px;
                          "
                          v-for="(btn, i) in rcs.richCard.suggestions"
                          :key="i"
                        >
                          <button
                            class="btn btn-primary left-animate"
                            type="button"
                          >
                            {{ btn.reply.text }}
                          </button>
                          <i
                            class="fas fa-edit margin-5 c-pointer font-13"
                            @click="richCardBtn = btn"
                          ></i>
                          <i
                            @click="btnRemoveRich(i)"
                            class="fas fa-times margin-5 c-pointer font-13"
                          ></i>
                        </div>
                      </div>
                      <hr />
                      <div v-if="richCardBtn">
                        <div class="">
                          <h3>{{$t('send-component.btn-edit')}}</h3>
                        </div>
                        <div class="">
                          <label for="sms-body">{{$t('send-component.title')}}</label>
                        </div>
                        <input
                          class="form-control"
                          v-model="richCardBtn.reply.text"
                        />
                        <div class="">
                          <label for="sms-body">{{$tc('generic-str.status.lbl-response', 1)}}</label>
                        </div>
                        <input
                          class="form-control"
                          v-model="richCardBtn.reply.postbackData"
                        />
                        <br />
                        <div>
                          <button
                            class="btn btn-danger btn-sm"
                            @click="richCardBtn = null"
                          >
                            <i class="fas fa-times color-white"></i>
                          </button>
                        </div>
                        <hr />
                      </div>
                      <button
                        class="btn btn-info to-right"
                        type="button"
                        @click="btnAddRich"
                      >
                        {{$t('send-component.new-btn')}}
                      </button>
                      <button
                        style="margin-right: 5px"
                        class="btn btn-success to-right"
                        type="button"
                        @click="saveMessage"
                      >
                        {{$t('generic-str.save')}}
                      </button>
                    </div>
                    <div class="col-sm-12" v-if="tabs == 'card-carousel'">
                      <div class="">
                        <h3>{{$t('options-div.types.carousel')}}</h3>
                      </div>
                      <div class="c-gallery" style="overflow: inherit; overflow-x: scroll;">
                        <div
                          class="c-gallery__img shadow-card rounded card-rich"
                          style="width: 300px; height: auto"
                          v-for="(rich, i) in rcs.richCarousels.images"
                          :key="i"
                        >
                          <div
                            class="card-rich-cover card-c-rich-cover relative"
                          >
                            <img
                              :src="rich.uri"
                              style="width: 300px"
                              :id="`card-rich-cover-${i}`"
                            />
                            <i
                              class="fas fa-edit card-edit-btn absolute"
                              style="right: 0px"
                              @click="
                                callCrop(
                                  'card-rich-cover-' + i,
                                  300,
                                  168,
                                  rich,
                                  'richCarousels',
                                  i,
                                )
                              "
                            ></i>
                            <i
                              class="fas fa-times card-edit-btn absolute"
                              v-if="rcs.richCarousels.images.length > 2"
                              style="right: 30px"
                              @click="btnRemoveCRich(i)"
                            ></i>
                          </div>
                          <br />
                          <h3 style="display: block; margin-top: 55px">
                            <span v-if="!rich.titleEdit">
                              {{ rich.title }}
                              <i
                                style="width: 3%"
                                class="fas fa-edit c-pointer font-13"
                                @click="rich.titleEdit = true"
                                v-if="!rich.titleEdit"
                              ></i>
                            </span>
                            <div class="relative">
                              <div
                                class="
                                  popup-box-container
                                  dropdown
                                  to-right
                                  var-insede-input
                                "
                                style="margin-left: 5px"
                                v-if="vars.length && rich.titleEdit"
                              >
                                <a href="javascript:void(0)" v-dropdown>
                                  <i
                                    class="fas fa-archive"
                                    v-tooltip.top="`Variáveis`"
                                  ></i>
                                </a>
                                <div
                                  class="dropdown-menu dropdown-menu-right"
                                  x-placement="bottom-end"
                                >
                                  <a
                                    class="dropdown-item"
                                    href="javascript:void(0)"
                                    v-for="variable in vars"
                                    :key="variable.value"
                                    @click="addVarCarouselTitle(variable, i)"
                                    >{{ variable.name }}</a
                                  >
                                </div>
                              </div>
                              <input
                                v-if="rich.titleEdit"
                                class="form-control"
                                v-model="rich.title"
                              />
                            </div>
                          </h3>
                          <p class="relative">
                            <span v-if="!rich.messageEdit">
                              {{ rich.description }}
                            </span>
                            <textarea
                              class="form-control"
                              style="width: 100%"
                              v-model="rich.description"
                              v-if="rich.messageEdit"
                            ></textarea>
                            <i
                              class="fas fa-edit c-pointer"
                              @click="rich.messageEdit = true"
                              v-if="!rich.messageEdit"
                            ></i>
                            <span
                              class="
                                popup-box-container
                                dropdown
                                to-right
                                var-insede-input
                              "
                              style="margin-right: 10px; margin-top: 10px"
                              v-if="vars.length && rich.messageEdit"
                            >
                              <a href="javascript:void(0)" v-dropdown>
                                <i
                                  class="fas fa-archive"
                                  v-tooltip.top="`Variáveis`"
                                ></i>
                              </a>
                              <div
                                class="dropdown-menu dropdown-menu-right"
                                x-placement="bottom-end"
                              >
                                <a
                                  class="dropdown-item"
                                  href="javascript:void(0)"
                                  v-for="variable in vars"
                                  :key="variable.value"
                                  @click="
                                    addVarCarouselDescription(variable, i)
                                  "
                                  >{{ variable.name }}</a
                                >
                              </div>
                            </span>
                          </p>
                          <hr class="no-margin" />
                          <div
                            v-if="rich.suggestions.length == 0"
                            style="padding: 15px"
                          >
                            <input
                              type="checkbox"
                              @change="addLinkCarousel(i)"
                            />
                            Habilitar Link
                          </div>
                          <div
                            style="margin: 10px; float: left"
                            v-for="(btn, j) in rich.suggestions"
                            :key="j"
                          >
                            <button
                              class="btn btn-primary left-animate"
                              type="button"
                            >
                              {{ btn.action.text }}
                            </button>
                            <i
                              class="fas fa-edit margin-5 c-pointer font-13"
                              @click="carouselCardBtn = btn"
                            ></i>
                            <i
                              class="fas fa-times margin-5 c-pointer font-13"
                              @click="btnRemoveLinkCarousel(i, j)"
                            ></i>
                          </div>
                          <div style="clear: both"></div>
                        </div>
                      </div>
                      <div v-if="carouselCardBtn">
                        <br />
                        <hr />
                        <div class="">
                          <h3>{{$t('send-component.btn-edit')}}</h3>
                        </div>
                        <div class="">
                          <label for="sms-body">{{$t('send-component.title')}}</label>
                        </div>
                        <input
                          class="form-control"
                          v-model="carouselCardBtn.action.text"
                        />
                        <div class="">
                          <label for="sms-body">{{$tc('generic-str.status.lbl-response', 1)}}</label>
                        </div>
                        <input
                          class="form-control"
                          v-model="carouselCardBtn.action.postbackData"
                        />
                        <div class="">
                          <label for="sms-body">URL</label>
                        </div>
                        <input
                          class="form-control"
                          v-model="carouselCardBtn.action.openUrlAction.url"
                        />
                        <br />
                        <div>
                          <button
                            class="btn btn-danger btn-sm"
                            @click="carouselCardBtn = null"
                          >
                            <i class="fas fa-times color-white"></i>
                          </button>
                        </div>
                        <hr />
                      </div>
                      <hr />
                      <button
                        class="btn btn-info to-right"
                        type="button"
                        @click="addCarouselCard"
                      >
                        Novo Card
                      </button>
                      <button
                        style="margin-right: 5px"
                        class="btn btn-success to-right"
                        type="button"
                        @click="saveMessage"
                      >
                        {{$t('generic-str.save')}}
                      </button>
                    </div>
                    <div class="col-sm-12" v-if="tabs == 'calendar'">
                      <div class="">
                        <h3>{{$t('options-div.types.events')}}</h3>
                      </div>
                      <div class="">
                        <label for="sms-body">{{$t('options-div.msg-text')}}</label>
                      </div>
                      <div class="text-right" style="margin-bottom: 5px">
                        <div
                          class="popup-box-container dropdown to-right"
                          style="margin-left: 5px"
                          v-if="vars.length"
                        >
                          <a href="javascript:void(0)" v-dropdown>
                            <span class="badge badge-primary">{{$t('sms.send-msg.sms-input-component.lbl-variables')}}</span>
                          </a>
                          <div
                            class="dropdown-menu dropdown-menu-right"
                            x-placement="bottom-end"
                          >
                            <a
                              class="dropdown-item"
                              href="javascript:void(0)"
                              v-for="variable in vars"
                              :key="variable.value"
                              @click="addVariableLink(variable)"
                              >{{ variable.name }}</a
                            >
                          </div>
                        </div>
                        <Emojis @select="addEmoji" title="Emoji" />
                      </div>
                      <textarea
                        class="form-control"
                        rows="6"
                        id="sms-body"
                        v-model="rcs.calendar.messageText"
                      ></textarea>
                      <div class="textarea-information bg-light text-right">
                        <small class="form-text text-muted">
                          {{ rcs.calendar.messageText.length }} {{$t('sms.send-msg.sms-input-component.lbl-chars')}}.
                        </small>
                      </div>
                      <hr />
                      <div class="c-gallery">
                        <div
                          class="c-gallery__img rounded card"
                          style="
                            width: 97% !important;
                            min-width: 97% !important;
                            height: auto;
                          "
                          v-for="(event, i) in rcs.calendar.suggestions"
                          :key="i"
                        >
                          <div class="card-content">
                            <div class="card-body">
                              <h4 class="card-title">
                                <span
                                  v-if="
                                    !event.action.createCalendarEventAction
                                      .titleEdit
                                  "
                                >
                                  {{
                                    event.action.createCalendarEventAction.title
                                  }}
                                </span>
                                <textarea
                                  class="form-control"
                                  style="
                                    width: 100%;
                                    float: left;
                                    margin-bottom: 10px;
                                  "
                                  v-model="
                                    event.action.createCalendarEventAction.title
                                  "
                                  v-if="
                                    event.action.createCalendarEventAction
                                      .titleEdit
                                  "
                                ></textarea>
                                <i
                                  class="fas fa-edit c-pointer"
                                  @click="
                                    event.action.createCalendarEventAction.titleEdit = true
                                  "
                                  v-if="
                                    !event.action.createCalendarEventAction
                                      .titleEdit
                                  "
                                ></i>
                                <i
                                  class="fas fa-times absolute"
                                  v-if="rcs.calendar.suggestions.length > 1"
                                  style="right: 10px"
                                  @click="btnRemoveEvent(i)"
                                ></i>
                              </h4>
                              <p style="display: block" class="card-text">
                                <span
                                  v-if="
                                    !event.action.createCalendarEventAction
                                      .descriptionEdit
                                  "
                                >
                                  {{
                                    event.action.createCalendarEventAction
                                      .description
                                  }}
                                  <i
                                    style="width: 3%"
                                    class="fas fa-edit c-pointer font-13"
                                    @click="
                                      event.action.createCalendarEventAction.descriptionEdit = true
                                    "
                                    v-if="
                                      !event.action.createCalendarEventAction
                                        .descriptionEdit
                                    "
                                  ></i>
                                </span>
                                <input
                                  v-if="
                                    event.action.createCalendarEventAction
                                      .descriptionEdit
                                  "
                                  class="form-control"
                                  v-model="
                                    event.action.createCalendarEventAction
                                      .description
                                  "
                                />
                              </p>
                              <hr />
                              <p style="display: block" class="card-text">
                                <span v-if="!event.action.textEdit">
                                  {{ event.action.text }}
                                  <i
                                    style="width: 3%"
                                    class="fas fa-edit c-pointer font-13"
                                    @click="event.action.textEdit = true"
                                    v-if="!event.action.textEdit"
                                  ></i>
                                </span>
                                <input
                                  v-if="event.action.textEdit"
                                  class="form-control"
                                  v-model="event.action.text"
                                />
                              </p>
                              <hr />
                              <div class="row">
                                <div
                                  class="col-sm-6 col-md-6 col-lg-6 col-sx-6"
                                >
                                  <p class="card-text">
                                    <small class="text-muted"
                                      >{{$t('options-div.lbl-start-date')}}</small
                                    >
                                  </p>
                                  <date-picker
                                    v-model="
                                      event.action.createCalendarEventAction
                                        .nstartTime
                                    "
                                    :config="options"
                                    class="form-control input-date"
                                    :placeholder="$t('generic-str.date-hour')"
                                  ></date-picker>
                                </div>
                                <div
                                  class="col-sm-6 col-md-6 col-lg-6 col-sx-6"
                                >
                                  <p class="card-text">
                                    <small class="text-muted"
                                      >{{$t('options-div.lbl-end-date')}}</small
                                    >
                                  </p>
                                  <date-picker
                                    v-model="
                                      event.action.createCalendarEventAction
                                        .nendTime
                                    "
                                    :config="options"
                                    class="form-control input-date"
                                    :placeholder="$t('generic-str.date-hour')"
                                  ></date-picker>
                                </div>
                              </div>
                              <hr
                                class="no-margin"
                                style="
                                  margin-top: 10px !important;
                                  margin-bottom: 10px !important;
                                "
                              />
                              <input
                                class="form-control"
                                v-model="event.action.postbackData"
                              />
                              <div style="clear: both"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style="clear: both"></div>
                      <hr />
                      <button
                        class="btn btn-info to-right"
                        type="button"
                        @click="btnAddEvent"
                      >
                        {{$t('options-div.new-event')}}
                      </button>
                      <button
                        style="margin-right: 5px"
                        class="btn btn-success to-right"
                        type="button"
                        @click="saveMessage"
                      >
                        {{$t('generic-str.save')}}
                      </button>
                    </div>
                    <div style="clear: both"></div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-8 col-sm-12">
                  <div class="card card-border-info">
                    <div class="card-header p-l-20 p-b-15 p-t-15">{{$t('options-div.title')}}</div>
                    <div class="card-body">
                      <div class="form-group row">
                        <div class="col-md-12">
                          <div
                            class="
                              custom-control
                              custom-checkbox
                              custom-control-inline
                              custom-control-inline-send
                            "
                          >
                            <input
                              class="tgl tgl-light tgl-primary calendar-change"
                              id="schedule"
                              type="checkbox"
                              v-model="form.schedule"
                              name="schedule"
                              @change="changeSchedule"
                            />
                            <label class="tgl-btn" for="schedule"></label>
                            <label class="label-send-check">{{$t('options-div.lbl-schedule')}}</label>
                          </div>
                          <date-picker
                            v-if="form.schedule"
                            v-model="form.datetime"
                            :config="options"
                            class="form-control input-date"
                            :placeholder="$t('generic-str.date-hour')"
                          ></date-picker>
                        </div>
                        <div class="col-md-12 hide">
                          <div
                            class="
                              custom-control
                              custom-checkbox
                              custom-control-inline
                              custom-control-inline-send
                            "
                          >
                            <input
                              class="tgl tgl-light tgl-primary calendar-change"
                              id="pubSub"
                              type="checkbox"
                              v-model="form.pub_sub"
                              name="pubSub"
                            />
                            <label class="tgl-btn" for="pubSub"></label>
                            <label class="label-send-check"
                              >{{$t('send-component.allow-response')}}</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="card-footer bg-light">
          <div class="alert alert-warning" v-if="!this.client.active">
             {{$t('generic-str.client-inactive')}}
            <strong
              ><a
                href="https://yupchat.typeform.com/to/b0aG4Maq"
                target="_blank"
                >{{$t('generic-str.request-access')}}</a
              ></strong
            >
          </div>
          <button
            @click="send"
            type="button"
            :class="{
              'qt-loader qt-loader-mini qt-loader-right': isSending,
              hide: !this.client.active,
            }"
            :disabled="isSending || importing"
            class="btn btn-primary float-right"
          >
            {{$t('generic-str.send')}}
          </button>
        </div>
      </div>
    </div>
    <create-contact-modal id="create-contact"></create-contact-modal>
    <create-group-modal id="create-group"></create-group-modal>
    <group-send-modal id="group-send-modal"></group-send-modal>
    <contact-send-modal id="contact-send-modal"></contact-send-modal>
    <m-crop id="m-crop" />
  </div>
</template>

<script>
// @ is an alias to /src
import axios from '@/plugins/axios';
import RCSService from '@/services/rcs.service';
import Tab from '@/directives/Tab';
import PageHeader from '@/components/PageHeader.vue';
import BaseSelect from '@/components/form/BaseSelect.vue';
import Utils from '@/models/utils';
import CreateContactModal from '@/components/contacts/CreateContactModal.vue';
import GroupSendModal from '@/components/groups/GroupSendModal.vue';
import ContactSendModal from '@/components/contacts/ContactSendModal.vue';
// import GroupTable from '@/components/groups/GroupTable.vue';
import CreateGroupModal from '@/components/groups/CreateGroupModal.vue';
import Swal from 'sweetalert2';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import $ from 'jquery';
import moment from 'moment';
import Emojis from '@/components/Emojis.vue';
import MCrop from '@/components/MCrop.vue';
import BucketService from '@/services/bucket.service';
import ImageUploader from '@/components/ImageUploader.vue';

$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});

const GSM = /^[ -~\n]*$/;

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
    CreateContactModal,
    ContactSendModal,
    GroupSendModal,
    ImageUploader,
    CreateGroupModal,
    datePicker,
    Emojis,
    MCrop,
    BaseSelect,
  },
  directives: {
    Tab,
  },
  data() {
    return {
      richCardBtn: null,
      carouselCardBtn: null,
      linkBtn: null,
      client: {},
      rcs: {
        text: {
          content: '',
        },
        midia: {},
        calendar: {
          messageText: '',
          suggestions: [
            {
              action: {
                textEdit: false,
                text: this.$t('options-div.btn-text'),
                postbackData: this.$t('options-div.lbl-response'),
                createCalendarEventAction: {
                  nstartTime: '2020-06-30T19:00:00Z',
                  nendTime: '2020-06-30T20:00:00Z',
                  title: this.$t('options-div.event-title'),
                  titleEdit: false,
                  description: this.$t('options-div.event-description'),
                  descriptionEdit: false,
                },
              },
            },
          ],
        },
        links: {
          messageText: '',
          suggestions: [
            {
              action: {
                text: this.$t('generic-str.action'),
                postbackData: 'Resposta 1',
                openUrlAction: {
                  url: 'https://www.google.com',
                },
              },
            },
          ],
        },
        richCard: {
          titleEdit: false,
          messageEdit: false,
          messageText: this.$t('options-div.card-title'),
          messageDescription: this.$t('options-div.card-text'),
          imageUrl:
            'https://i.pinimg.com/originals/0c/96/b1/0c96b19dc89ffdaa7ff737cfc04a095f.png', // '/assets/img/default_card_bg.jpeg',
          height: 'TALL',
          suggestions: [
            {
              reply: {
                text: this.$t('generic-str.action'),
                postbackData: 'Ok',
              },
            },
          ],
        },
        richCarousels: {
          images: [
            {
              titleEdit: false,
              messageEdit: false,
              title: this.$t('options-div.card-title'),
              description: this.$t('options-div.card-text'),
              uri: 'https://i.pinimg.com/originals/0c/96/b1/0c96b19dc89ffdaa7ff737cfc04a095f.png',
              height: 'MEDIUM',
              postbackData: 'Ok',
              suggestions: [
                {
                  action: {
                    text: 'Link',
                    postbackData: 'Resposta 1',
                    openUrlAction: {
                      url: 'https://www.google.com',
                    },
                  },
                },
              ],
            },
            {
              titleEdit: false,
              messageEdit: false,
              title: this.$t('options-div.card-title'),
              description: this.$t('options-div.card-text'),
              uri: 'https://i.pinimg.com/originals/0c/96/b1/0c96b19dc89ffdaa7ff737cfc04a095f.png',
              height: 'MEDIUM',
              postbackData: 'Ok',
              suggestions: [
                {
                  action: {
                    text: 'Link',
                    postbackData: 'Resposta 1',
                    openUrlAction: {
                      url: 'https://www.google.com',
                    },
                  },
                },
              ],
            },
          ],
        },
      },
      tabs: 'text',
      shortCodes: [],
      cropActive: {},
      addVars: false,
      hasVar: false,
      groups: [],
      isSending: false,
      contacts: [],
      selectedGroups: [],
      selectedContacts: [],
      selectedFiles: [],
      importing: false,
      vars: [],
      value: null,
      fileVariables: [],
      files: {
        richCard: {},
        richCarousels: [],
      },
      indexFile: 0,
      products: [],
      form: {
        type: 'simple',
        from: 'Default',
        pub_sub: true,
        to: '',
        body: '',
        file: '',
        normalize: false,
        flash: false,
        template: null,
        text_type: 'text',
      },
      options: {
        format: 'DD/MM/YYYY HH:mm',
        useCurrent: true,
        minDate: moment(),
        locale: 'pt',
        keepInvalid: true,
      },
      replace: false,
      flash: false,
      segments: 1,
      submited: false,
      length: 0,
      uploadProgress: 0,
    };
  },
  computed: {
    classes() {
      return ['wizard'];
    },
  },
  created() {
    this.fetchClient();
    console.log(
      moment.tz('09/06/2021 11:45', 'DD/MM/YYYY HH:mm', 'UTC').format(),
    );
    this.fetchProducts();
  },
  methods: {
    addButton(card, type) {
      card.buttons.push({
        text: 'text',
        type,
        start_time: moment().startOf('day').format(),
        end_time: moment().endOf('day').format(),
      });
      this.$emit('input', this.buttons);
    },
    addCarouselCard() {
      if (this.rcs.richCarousels.images.length < 10) {
        this.rcs.richCarousels.images.push({
          titleEdit: false,
          messageEdit: false,
          title: this.$t('options-div.card-title'),
          description: this.$t('options-div.card-text'),
          uri: 'https://i.pinimg.com/originals/0c/96/b1/0c96b19dc89ffdaa7ff737cfc04a095f.png', // '/assets/img/default_card_bg.jpeg',
          height: 'MEDIUM',
          postbackData: '',
          suggestions: [
            {
              action: {
                text: 'Link',
                postbackData: 'Resposta 1',
                openUrlAction: {
                  url: 'https://www.google.com',
                },
              },
            },
          ],
        });
      }
    },
    addLinkCarousel(i) {
      this.rcs.richCarousels.images[i].suggestions.push({
        action: {
          text: 'Link',
          postbackData: 'Resposta 1',
          openUrlAction: {
            url: 'https://www.google.com',
          },
        },
      });
    },
    btnAddEvent() {
      this.rcs.calendar.suggestions.push({
        action: {
          text: this.$t('options-div.btn-text'),
          postbackData: this.$t('options-div.lbl-response'),
          createCalendarEventAction: {
            nstartTime: '2020-06-30T19:00:00Z',
            nendTime: '2020-06-30T20:00:00Z',
            title: this.$t('options-div.event-title'),
            description: this.$t('options-div.event-description'),
          },
        },
      });
    },
    btnAddLink() {
      this.rcs.links.suggestions.push({
        action: {
          text: this.$t('generic-str.action'),
          postbackData: 'Ação resposta',
          openUrlAction: {
            url: 'https://www.google.com',
          },
        },
      });
    },
    btnRemoveLink(index) {
      this.rcs.links.suggestions.splice(index, 1);
    },
    btnRemoveLinkCarousel(card, index) {
      this.rcs.richCarousels.images[card].suggestions.splice(index, 1);
    },
    btnAddRich() {
      this.rcs.richCard.suggestions.push({
        reply: {
          text: this.$t('generic-str.action'),
          postbackData: 'Ok',
        },
      });
    },
    btnRemoveRich(index) {
      this.rcs.richCard.suggestions.splice(index, 1);
    },
    btnRemoveCRich(index) {
      this.rcs.richCarousels.images.splice(index, 1);
    },
    btnRemoveEvent(index) {
      if (this.rcs.calendar.suggestions.length > 1) {
        this.rcs.calendar.suggestions.splice(index, 1);
      }
    },
    crop(data) {
      this.cropActive.dataCrop = data;
      this.$root.$emit('show.modal', 'request-modal');
      if (data) {
        if (this.tabs === 'card') {
          this.files[data.name] = data;
          document.getElementById(this.cropPreview).src = `${
            this.files[data.name].url
          }`;
        }
        if (this.tabs === 'card-carousel') {
          this.files[data.name][this.indexFile] = data;
          document.getElementById(this.cropPreview).src = `${
            this.files[data.name][this.indexFile].url
          }`;
          this.files[data.name][this.indexFile].index = this.indexFile;
        }
      }
      console.log(this.files);
    },
    callCrop(
      cropPreview,
      maxWidthP,
      maxHeightP,
      cropActive,
      nameP = null,
      index = 0,
    ) {
      this.cropPreview = cropPreview;
      this.cropActive = cropActive;
      this.indexFile = index;
      this.$root.$emit('show.modal', 'm-crop');
      this.$store.dispatch('crop/config', {
        maxWidth: maxWidthP,
        maxHeight: maxHeightP,
        name: nameP,
        viewMode: 1,
        aspectRatio: 2,
        minSize: true,
        infoSize: '1700x800',
      });
    },
    setValue(value) {
      this.$emit('input', value);
    },
    addEmoji(emoji) {
      if (this.tabs === 'text') {
        this.rcs.text.content = `${this.rcs.text.content}${emoji}`;
      }
      if (this.tabs === 'link') {
        this.rcs.links.messageText = `${this.rcs.links.messageText}${emoji}`;
      }
      if (this.tabs === 'calendar') {
        this.rcs.calendar.messageText = `${this.rcs.calendar.messageText}${emoji}`;
      }
    },
    addVariable(variable) {
      this.rcs.text.content = `${this.rcs.text.content}{${variable.value}}`;
    },
    addVarCarouselTitle(variable, i) {
      this.rcs.richCarousels.images[
        i
      ].title = `${this.rcs.richCarousels.images[i].title}{${variable.value}}`;
    },
    addVarCardTitle(variable, i) {
      this.rcs.richCard.messageText = `${this.rcs.richCard.messageText}{${variable.value}}`;
    },
    addVarCardDescription(variable, i) {
      this.rcs.richCard.messageDescription = `${this.rcs.richCard.messageDescription}{${variable.value}}`;
    },
    addVarCarouselDescription(variable, i) {
      this.rcs.richCarousels.images[
        i
      ].description = `${this.rcs.richCarousels.images[i].description}{${variable.value}}`;
    },
    addVariableLink(variable) {
      this.rcs.links.messageText = `${this.rcs.links.messageText}{${variable.value}}`;
    },
    changeTextTab(tab) {
      this.tabs = tab;
      this.form.text_type = tab;
    },
    fetchClient() {
      Promise.all([RCSService.getAgent()])
        .then(
          (responses) => {
            this.client = responses[0].data;
            this.fetched = true;
            this.form.client_id = this.client.id;
          },
          (error) => {
            this.content = error;
          },
        )
        .finally(() => {
          this.fetched = true;
        });
    },
    fetchProducts() {
      RCSService.getProducts().then(
        (products) => {
          this.products = products;
          if (products) {
            this.form.productId = products[0].id;
          }
        },
        (error) => {
          this.content = error;
        },
      );
    },
    selectedGroupsChange(param) {
      param.selectedGroups.forEach((group) => {
        if (!this.selectedGroups.includes(group.id)) {
          this.groups.push(group);
          this.selectedGroups.push(group.id);
        }
      });
    },
    selectedContactsChange(param) {
      param.selectedContacts.forEach((contact) => {
        if (!this.selectedContacts.includes(contact.id)) {
          this.contacts.push(contact);
          this.selectedContacts.push(contact.id);
        }
      });
    },
    selectedFilesChange(param) {
      param.selectedFiles.forEach((file) => {
        if (!this.selectedFiles.includes(file.id)) {
          this.files.push(file);
          this.selectedFiles.push(file.id);
        }
      });
    },
    changeType(type) {
      if (type === 'contacts' || type === 'groups' || type === 'files') {
        this.vars = [
          { name: this.$t('generic-str.lbl-first-name'), value: 'first_name' },
          { name: this.$t('generic-str.lbl-last-name'), value: 'last_name' },
          { name: this.$t('generic-str.lbl-cellphone'), value: 'mobile_number' },
          { name: 'Email', value: 'email' },
        ];
      } else if (type === 'files') {
        this.vars = this.fileVariables;
      } else {
        this.vars = [];
      }
      this.form.type = type;
      this.submited = false;
    },
    openFileUpload() {
      this.$refs.uploadedFile.click();
    },
    uploadFile(event) {
      const self = this;
      const reader = new FileReader();
      this.importing = true;
      const formData = new FormData();
      const file = event.target.files[0];
      formData.append('file', file);
      this.form.file = file.name;

      reader.onload = () => {
        const variables = reader.result
          .split('\n')
          .shift()
          .replace('\r', '')
          .split(';'); // first line
        variables.forEach((variable) => {
          self.fileVariables.push({
            value: variable,
            name: variable,
          });
        });
      };
      reader.readAsText(file, 'UTF-8');

      const config = {
        onUploadProgress: (progressEvent) => {
          this.uploadProgress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total,
          );
        },
      };

      axios()
        .post('files', formData, config)
        .then(
          () => {
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.send-component.success'),
              type: 'success',
            });
          },
          (error) => {
            self.form.file = '';
            self.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
          },
        )
        .finally(() => {
          this.importing = false;
          this.uploadProgress = 0;
        });
    },
    removeGroup(i) {
      this.groups.splice(i, 1);
      this.selectedGroups.splice(i, 1);
      this.$root.$emit('groups.selected.refresh', {
        selectedGroups: this.groups,
      });
    },
    removeContact(i) {
      this.contacts.splice(i, 1);
      this.selectedContacts.splice(i, 1);
      this.$root.$emit('contacts.selected.refresh', {
        selectedContacts: this.contacts,
      });
    },
    removeFile(i) {
      this.files.splice(i, 1);
      this.selectedFiles.splice(i, 1);
    },
    addVar(entity) {
      this.addVars = false;
      this.form.body = this.form.body.concat(` {${entity}} `);
      this.hasVar = true;
      this.countSegments(15);
    },
    smsTextChange() {
      let contVar = false;
      if (this.type === 'files') {
        this.fileVariables.forEach((element) => {
          if (this.form.body.includes(element.value)) {
            contVar = true;
          }
          this.hasVar = contVar;
        });
      } else {
        this.vars.forEach((element) => {
          if (this.form.body.includes(element.value)) {
            contVar = true;
          }
          this.hasVar = contVar;
        });
      }
    },
    showAddVar() {
      this.addVars = true;
    },
    hideAddVar() {
      this.addVars = false;
    },
    changeSchedule() {},
    countSegments(newValues) {
      console.log(newValues);
      this.length = this.form.body.length + newValues;
    },
    insertTemplate(param) {
      const template = JSON.parse(param);
      this.form.template = template;
      this.form.body = template.body;
      this.countSegments();
      // form.body = e.target.value;
    },
    send() {
      this.submited = true;
      if (this.form.datetime) {
        const scheduledAt = moment(
          this.form.datetime,
          'DD/MM/YYYY HH:mm:ss',
        ).format('YYYY-MM-DD HH:mm:ss');
        this.form.scheduled_at = scheduledAt;
      }
      switch (this.form.type) {
        case 'simple':
          this.form.contacts = [];
          this.form.groups = [];

          if (this.form.to.length > 0) {
            this.sendRequest();
          }
          break;
        case 'contacts':
          if (this.selectedContacts.length > 0) {
            this.form.contacts = this.selectedContacts;
            this.form.to = '';

            this.sendRequest();
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: 'error',
              title: this.$t('warnings.send-component.contact'),
            });
          }
          break;
        case 'groups':
          this.form.contacts = [];
          this.form.to = '';

          if (this.selectedGroups.length > 0) {
            this.form.groups = this.selectedGroups;
            this.sendRequest();
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: 'error',
              title: this.$t('warnings.send-component.group'),
            });
          }
          break;
        case 'files':
          if (this.form.file) {
            this.form.files = this.selectedFiles;
            this.sendRequest();
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: 'error',
              title: this.$t('warnings.send-component.archive'),
            });
          }
          break;
        default:
      }
    },
    sendRequest() {
      Swal.fire({
        title: 'Enviar Mensagens',
        text: this.$t('warnings.send-component.send-msg-confirm'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          this.isSending = true;
          this.form.rcs = this.rcs;
          switch (this.tabs) {
            case 'text':
              this.sendMessage();
              break;
            case 'midia':
              if (Utils.isMidia(this.form.rcs.midia.fileUrl) !== undefined) {
                this.sendMessage();
              } else {
                this.$toast.show({
                  title: this.$t('sms.infos.filters.status.lbl-error'),
                  content: 'A url mídia é inválida',
                  type: 'danger',
                });
                this.isSending = false;
              }
              break;
            case 'link':
              this.sendMessage();
              break;
            case 'calendar':
              this.form.rcs.calendar.suggestions.forEach((element) => {
                const startTime =
                  element.action.createCalendarEventAction.nstartTime;
                const endTime =
                  element.action.createCalendarEventAction.nendTime; // 'YYYY-MM-DD HH:mm:ss'
                // moment.tz('09/06/2021 11:45', 'DD/MM/YYYY HH:mm', 'UTC').format()
                const newStartTime = moment
                  .tz(startTime, 'DD/MM/YYYY HH:mm', 'UTC')
                  .format();
                const newEndTime = moment
                  .tz(endTime, 'DD/MM/YYYY HH:mm', 'UTC')
                  .format();
                console.log(newStartTime);
                element.action.createCalendarEventAction.startTime =
                  newStartTime;
                element.action.createCalendarEventAction.endTime = newEndTime;
              });
              console.log(this.form.rcs.calendar);

              this.sendMessage();
              break;
            case 'card-carousel':
              this.isSending = true;
              if (this.files.richCarousels.length > 0) {
                const filesCrop = [];
                const formData = new FormData();
                this.files.richCarousels.forEach((element) => {
                  filesCrop.push({
                    x: element.x,
                    y: element.y,
                    w: element.width,
                    h: element.height,
                  });
                  formData.append('files[]', element.file);
                });
                formData.append('role', 'public');
                formData.append('folder', 'rcs');
                formData.append('crop', JSON.stringify(filesCrop));
                console.log(this.files.richCarousels);
                console.log(filesCrop);

                Promise.all([
                  BucketService.upload(formData, true, this.callBackProgress),
                ])
                  .then(
                    (responses) => {
                      console.log(responses);
                      let count = 0;
                      this.files.richCarousels.forEach((element) => {
                        this.rcs.richCarousels.images[element.index].uri =
                          responses[0].data[count].url;
                        delete this.rcs.richCarousels.images[element.index]
                          .dataCrop;
                        count += 1;
                      });
                      console.log(this.rcs.richCarousels);
                      // this.form.rcs.richCard.imageUrl = responses[0].data.url;
                      this.sendMessage();
                    },
                    (error) => {
                      this.content = error;
                      this.isSending = false;
                      console.log(error);
                    },
                  )
                  .finally(() => {
                    this.isSending = false;
                  });
              } else {
                this.sendMessage();
              }
              break;
            case 'card':
              this.isSending = true;
              if (
                this.files.richCard.file !== null &&
                this.files.richCard.file !== undefined
              ) {
                const formDataRich = new FormData();
                const filesCrop = [];
                filesCrop.push({
                  x: this.files.richCard.x,
                  y: this.files.richCard.y,
                  w: this.files.richCard.width,
                  h: this.files.richCard.height,
                });
                formDataRich.append('files[]', this.files.richCard.file);
                formDataRich.append('role', 'public');
                formDataRich.append('folder', 'rcs');
                formDataRich.append('crop', JSON.stringify(filesCrop));

                Promise.all([
                  BucketService.upload(
                    formDataRich,
                    true,
                    this.callBackProgress,
                  ),
                ])
                  .then(
                    (responses) => {
                      console.log(responses);
                      console.log(responses[0].data.url);
                      this.form.rcs.richCard.imageUrl =
                        responses[0].data[0].url;
                      delete this.form.rcs.richCard.dataCrop;
                      console.log(this.form);
                      this.sendMessage();
                    },
                    (error) => {
                      this.content = error;
                      this.isSending = false;
                      console.log(error);
                    },
                  )
                  .finally(() => {
                    this.isSending = false;
                  });
              } else {
                this.sendMessage();
              }
              break;
            default:
          }
        }
      });
    },
    callBackProgress(progress) {
      this.uploadProgress = progress;
    },
    sendMessage() {
      RCSService.send(this.form)
        .then(
          (send) => {
            if (this.form.type === 'simple') {
              this.$router.push({
                name: 'RCSSuccess',
                params: { sms: send.data },
              });
            } else {
              this.$router.push('/rcs/campaigns');
            }
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.send-component.msg-registered'),
              type: 'success',
            });
            this.isSending = false;
          },
          (error) => {
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        )
        .catch((error) => {
          console.log(error.response);
          this.isSending = false;
        });
    },
    saveMessage() {
      this.$toast.show({
        title: this.$t('generic-str.success'),
        content: 'Salvo com sucesso',
        type: 'success',
      });
    },
    putEmoji(emoji) {
      if (this.form.body.length) {
        this.form.body += ` ${emoji}`;
      } else {
        this.form.body = emoji;
      }
      this.countSegments();
    },
  },
  mounted() {
    this.$root.$on('groups.addonsend', this.selectedGroupsChange);
    this.$root.$on('contacts.addonsend', this.selectedContactsChange);
    this.$root.$on('files.addonsend', this.selectedFilesChange);
    this.$root.$on('call.crop', this.crop);
  },
};
</script>

<style lang="scss">
.card-rich .card-rich-cover {
  height: 250px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.card-rich h2 {
  padding: 10px;
}
.card-rich h3 {
  padding: 10px;
}
.card-rich p {
  padding: 10px;
}
textarea:not(:last-child) {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.textarea-information {
  border: 1px solid #dfe7f3;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 0.2rem 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
textarea:focus + .textarea-information {
  border: 1px solid #635ebe;
  border-top: none;
}
.input-date {
  margin-top: 15px !important;
}
.form-control.invalid {
  border-color: #ff5c75;
}
.actions-var {
  position: relative !important;
}
.emoji-color {
  color: #a6a6a6 !important;
}
.send-group-card {
  box-shadow: none;
}
.send-group-card .table-placeholder i,
h5 {
  color: #b5b5b5;
}
.actions-var-cancel {
  text-align: center;
  color: #4d5a68;
  border-top: 1px solid rgba(234, 243, 253, 0.7);
  padding-top: 5px;
  padding-bottom: 0px;
}
.actions-icon {
  font-size: 14px !important;
}
.actions-var a {
  cursor: pointer;
}
.custom-control-inline-send {
  margin-left: 0px !important;
  padding-left: 0px !important;
}
.label-send-check {
  margin-left: 10px;
  margin-top: 2px;
}
.ul-list-groups {
  margin: 0px;
  padding: 0px;
}
.ul-list-groups li {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.card-send-content {
  border: solid 1px #e4e4e4;
  border-radius: 10px;
  margin: 0px;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 25px;
}
.card-edit-btn {
  margin: 10px;
  color: #fff;
  cursor: pointer;
}
.card-edit-btn:hover {
  color: rgb(230, 230, 230);
}
.card-c-rich-cover {
  height: 90px !important;
}
.c-gallery {
  overflow-x: auto;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  display: flex;
}

.c-gallery__img {
  scroll-snap-align: start;
  margin-bottom: 10px;
  min-width: 30%;
  object-fit: cover;
  height: 220px;
  margin: 10px;
}

.var-insede-input {
  margin-left: 5px;
  position: absolute;
  right: 5px;
  top: 5px;
}
.var-insede-input i {
  font-size: 15px !important;
}
</style>
